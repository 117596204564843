@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

/* table */
table {
    font-family: 'IBM Plex Sans';
    border-collapse: collapse;
    border: none;
    width: 100%;
    }

tr {
    border-radius: 10px;
}

td, th {
    text-align: center;
    padding: 8px;
    border: none;
    font-size: 13px;
    height: 30px;
}

tr:nth-child(odd) {
    background-color: #CFE1F9;
}

tr:nth-last-child(-n+1) {
    background-color: #CFE1F9;
}

tr:nth-last-child(-n+1) {
    background-color: #CFE1F9;
}

tr:nth-last-child(2) {
    color: #FF2323;
}